<template>
  <div>
    <v-card outlined class="margin-bottom-24">
      <div class="px-sm-6 px-4">
        <v-card-title class="card-title">{{
          $t("Reservation.MyRepresentative")
        }}</v-card-title>
        <v-divider class="mt-0 mx-0" />
        <TheSalesAgents
          v-if="$store.getters.reservationAgent"
          :agents="$store.getters.reservationAgent"
          :selected="true"
        />
        <template v-else>
          <v-card-title class="card-minTitle">{{
            $t("Reservation.SelectAgent")
          }}</v-card-title>
          <v-card-text class="card-text card-subtitle">
            {{ $t("Reservation.SelectAgentDesc") }}
          </v-card-text>
        </template>
        <v-card-actions class="justify-end pb-4">
          <v-dialog width="1200" v-model="agentPop">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                text
                large
                v-bind="attrs"
                v-on="on"
                v-if="$store.getters.reservationAgent"
                class="px-0 change-rep-btn"
              >
                {{ $t("Reservation.ChangeRepresentative") }}
              </v-btn>
              <v-btn
                color="primary"
                rounded
                elevation="0"
                large
                v-bind="attrs"
                v-on="on"
                class="choose-btn"
                v-else
              >
                {{ $t("Reservation.ChooseNow") }}
              </v-btn>
            </template>

            <v-card>
              <v-card-title
                color="primary"
                class="text-h6 primary text-white flex justify-space-between modal-title"
              >
                <div class="font-600">
                  {{ $t("Reservation.ChooseRepresentative") }}
                </div>
                <v-btn dark icon @click="agentPop = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>

              <v-container class="p-0">
                <div class="agent-wrapper">
                  <div
                    class="agent-box"
                    @click="agentPop = false"
                    v-for="agent in theAgents"
                    :key="agent.name"
                  >
                    <TheSalesAgents :agents="agent" />
                  </div>
                </div>
              </v-container>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </div>
    </v-card>
    <template v-if="$store.getters.reservationAgent">
      <v-card outlined class="margin-bottom-24" v-if="!logState">
        <div class="px-sm-6 px-4">
          <v-card-title class="card-title">{{
            $t("Reservation.MyContact")
          }}</v-card-title>
          <v-divider class="m-0" />
          <v-card-text class="card-text mycontact-text">
            {{ $t("Reservation.MyContactDesc") }}
          </v-card-text>
          <v-card-actions class="mycontact-buttons">
            <v-btn
              color="primary"
              rounded
              elevation="0"
              @click.prevent.stop="$bvModal.show('signin')"
            >
              {{ $t("navBar.label.signIn") }}
            </v-btn>
            <v-btn
              color="primary"
              outlined
              rounded
              elevation="0"
              @click="createAccount"
            >
              {{ $t("Reservation.CreateAccount") }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
      <v-card outlined class="margin-bottom-24" v-else id="reservation-userinfo-area">
        <div class="reservation-userinfo-wrapper">
          <v-card-title class="card-title">{{
            $t("Reservation.MyContact")
          }}</v-card-title>
          <v-divider class="m-0" />
          <v-card-text class="card-text p-0">
            <div>
              <div class="reservation-userinfo">
                <div class="item">
                  <div class="card-label">
                    {{ $t("Reservation.FullName") }}:
                  </div>
                  <div>
                    {{ user.Contact.FirstName }} {{ user.Contact.LastName }}
                  </div>
                </div>
                <div class="item">
                  <div class="card-label">{{ $t("Reservation.Email") }}:</div>
                  <div>{{ user.Contact.Email.toLowerCase() }}</div>
                </div>
                <div class="item">
                  <div class="card-label">{{ $t("Reservation.Country") }}:</div>
                  <div>{{ user.Contact.Country.Name }}</div>
                </div>
                <div class="item">
                  <div class="card-label">{{ $t("Reservation.Mobile") }}:</div>
                  <div>
                    {{
                      IsMobileVerified
                        ? user.Contact.Mobile
                        : $t("Reservation.phoneNotAvailable")
                    }}
                  </div>
                </div>
              </div>
              <v-alert prominent type="error" text v-if="!IsEmailVerified">
                <v-row align="center">
                  <v-col class="grow">
                    {{ $t("Reservation.IsEmailVerifiedDesc") }}
                  </v-col>
                  <v-col class="shrink">
                    <v-btn :to="{ name: 'RegisterPage' }">{{
                      $t("Reservation.ValidateEmail")
                    }}</v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </div>
          </v-card-text>
          <template v-if="!IsMobileVerified">
            <v-divider />
            <v-card-actions class="justify-start provide-mobile">
              <div>
                <label class="provide-mobile-label">{{
                  $t("Reservation.PleaseProvideMobileNumber")
                }}</label>
                <TheMobileValidation @mobileChange="handleMobile" />
              </div>
            </v-card-actions>
          </template>
        </div>
      </v-card>
      <template v-if="IsMobileVerified && IsEmailVerified">
        <v-card outlined class="margin-bottom-24">
          <div class="radio-card-holder">
            <v-card-title class="card-minTitle pl-0">{{
              $t("Reservation.checkDeliveryPerson")
            }}</v-card-title>
            <div class="ml-4">
              <v-radio-group
                v-model="sameAddress"
                @change="pickUp"
                class="mt-0"
                row
              >
                <v-radio :label="$t('global.button.yes')" :value="true" />
                <v-radio :label="$t('global.button.no')" :value="false" />
              </v-radio-group>
            </div>
          </div>
        </v-card>
        <v-card outlined class="margin-bottom-24" v-if="!sameAddress">
          <div class="reservation-userinfo-wrapper">
            <v-card-title
              class="card-title d-flex align-center justify-space-between"
            >
              <div>{{ $t("Reservation.ReceiversContact") }}</div>
              <div class="text--primary" v-if="!other_formShow">
                <v-btn text color="primary" @click="() => {other_formShow = true; receiverIsSet = false}">
                  <v-icon>mdi-pen</v-icon>{{ $t("Reservation.Edit") }}
                </v-btn>
              </div>
            </v-card-title>
            <v-divider class="m-0" />

            <v-card-text
              class="card-text px-0 pb-0 mb-6 pt-6"
              v-if="other_formShow"
              >{{ $t("Reservation.ReceiversContactDesc") }}</v-card-text
            >

            <v-card-text class="card-text p-0">
              <div>
                <div class="reservation-userinfo" v-if="!other_formShow">
                  <div class="item">
                    <div class="card-label">
                      {{ $t("Reservation.FullName") }}:
                    </div>
                    <div>{{ other_firstName }} {{ other_lastName }}</div>
                  </div>
                  <div class="item">
                    <div class="card-label">{{ $t("Reservation.Email") }}:</div>
                    <div>{{ other_email }}</div>
                  </div>
                  <div class="item">
                    <div class="card-label">
                      {{ $t("Reservation.Mobile") }}:
                    </div>
                    <div>{{ other_mobilePayload.number }}</div>
                  </div>
                </div>
                <v-form
                  v-else
                  ref="otherForm"
                  class="border rounded-lg mx-auto margin-bottom-24 contact-form"
                >
                  <div>
                    <div>
                      <label for="firstName">{{
                        $t("Reservation.FirstName")
                      }}</label>
                      <v-text-field
                        :height="40"
                        v-model="$v.other_firstName.$model"
                        id="firstName"
                        dense
                        :error="
                          $v.other_firstName.$dirty && $v.other_firstName.$error
                        "
                        :error-messages="
                          $v.other_firstName.$dirty && $v.other_firstName.$error
                            ? $t('Reservation.FirstNameNotValid')
                            : undefined
                        "
                      />
                    </div>
                    <div>
                      <label for="lastName">{{
                        $t("Reservation.LastName")
                      }}</label>
                      <v-text-field
                        :height="40"
                        v-model="$v.other_lastName.$model"
                        dense
                        id="lastName"
                        :error="
                          $v.other_lastName.$dirty && $v.other_lastName.$error
                        "
                        :error-messages="
                          $v.other_lastName.$dirty && $v.other_lastName.$error
                            ? $t('Reservation.LastNameNotValid')
                            : undefined
                        "
                      />
                    </div>
                    <div>
                      <label for="email">{{ $t("Reservation.Email") }}</label>
                      <v-text-field
                        :height="40"
                        id="email"
                        v-model="$v.other_email.$model"
                        dense
                        :error="$v.other_email.$dirty && $v.other_email.$error"
                        :error-messages="
                          $v.other_email.$dirty && $v.other_email.$error
                            ? $t('Reservation.EmailNotValid')
                            : undefined
                        "
                      />
                    </div>

                    <div>
                      <label for="mobile">{{ $t("Reservation.Mobile") }}</label>
                      <b-input-group class="row m-0 phone-wrapper">
                        <VuePhoneNumberInput
                          class="col p-0"
                          v-model.trim="other_mobile"
                          :default-country-code="
                            deliveryCountry ?
                            deliveryCountry.Code :
                            other_mobilePayload
                              ? other_mobilePayload.country
                              : 'CA'
                          "
                          noExample
                          :style="{border: 'none!important'}"
                          @update="setMobileNumber($event)"
                          :translations="{
                            countrySelectorLabel: $t('Contact.countrySelectorLabel'),
                            countrySelectorError: $t('Contact.countrySelectorError'),
                            phoneNumberLabel: $t('Contact.phoneNumberLabel'),
                            example: $t('Contact.example'),
                          }"
                        />
                      </b-input-group>
                    </div>
                    <div class="py-0 margin-bottom-24 submit-btn">
                      <v-btn
                        block
                        rounded
                        color="primary"
                        large
                        @click="validateOtherForm"
                      >
                        {{ $t("Reservation.Submit") }}
                      </v-btn>
                    </div>
                  </div>
                </v-form>
              </div>
            </v-card-text>
          </div>
        </v-card>
      </template>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import TheMobileValidation from "../global/TheMobileValidation.vue";
import TheSalesAgents from "@/components/reservation/TheSalesAgentCard";
import countryCodes from "./countryCodes";
import { required, email,  } from "vuelidate/lib/validators";
import VuePhoneNumberInput from "vue-phone-number-input";
import parsePhoneNumber from "libphonenumber-js";
const ValidationOtherMobile = (value) => value && value.isValid

export default {
  components: {
    TheMobileValidation,
    TheSalesAgents,
    VuePhoneNumberInput,
  },
  props: {
    nextButton: { type: Function },
  },
  updated() {
    this.activeNextButton();
  },
  watch: {
    sameAddress: function(val) {
      this.resetOtherFields();
      if (!val) {
        this.checkInput("reservationSameAddress", false);
        this.other_formShow = true;
        this.$v.$reset();
      }
    },
  },
  data() {
    return {
      agents: false,
      agentPop: false,
      theAgents: [],
      firstName: null,
      lastName: null,
      email: null,
      mobile: null,
      mobileCountryCode: null,
      mobileIsValid: false,
      address: null,
      other_formShow: false,
      other_firstName: null,
      other_lastName: null,
      other_email: null,
      other_mobile: null,
      other_mobile_countryCode: null,
      other_mobilePayload: null,
      receiverIsSet: true,
      sameAddress: this.$store.getters.reservationSameAddress,
      errorMassage: null,
      verification: false,
      verified: false,
      verificationCode: null,
      deliveryCountry: {},
    };
  },
  validations: {
    other_firstName: {
      required,
    },
    other_lastName: {
      required,
    },
    other_email: {
      required,
      email,
    },
    other_mobile: {
      required,
    },
    other_mobilePayload: {
      required,
      ValidationOtherMobile
    }
  },
  methods: {
    setMobileNumber(event){
      if(!event.phoneNumber) return false;
      const mobileNumber = parsePhoneNumber(event.phoneNumber.toString(), event.countryCode);

      if(mobileNumber) {
        const payload = {
          ...mobileNumber,
          isValid: mobileNumber ? mobileNumber.isValid() : false,
          countryCallingCode: mobileNumber.countryCallingCode,
          formatInternational: mobileNumber.formatInternational(),
          formatNational: mobileNumber.number,
          formattedNumber: mobileNumber.number,
          uri: mobileNumber.getURI(),
          e164: mobileNumber.ext
        }
        this.other_mobilePayload = payload;
        this.other_mobile_countryCode = payload.countryCallingCode;
      }
    },
    createAccount() {
      this.$store.dispatch(`accountType`, 1);
      this.$store.dispatch("reservationKeepData", true);
      //debugger
      //this.$store.dispatch("previousPageName", this.$route);
      setTimeout(() => {
        this.$router.push({ name: "RegisterPage" });
      },1000);
    },
    validateOtherForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.other_formShow = false;
        this.checkInput(
          "reservationOther_ContactFirstName",
          this.other_firstName
        );
        this.checkInput(
          "reservationOther_ContactLastName",
          this.other_lastName
        );
        this.checkInput(
          "reservationOther_ContactMobile",
            this.other_mobilePayload && this.other_mobilePayload.number
        );
        this.checkInput(
          "reservationOther_ContactMobileCountryCode",
          this.other_mobile_countryCode
        );
        this.checkInput("reservationOther_ContactEmail", this.other_email);
        this.checkInput("reservationSameAddress", false);
        this.receiverIsSet = true;
      }
    },
    resetOtherFields() {
      this.other_firstName = undefined;
      this.other_lastName = undefined;
      this.other_mobile = undefined;
      this.other_mobile_countryCode = undefined;
      this.other_email = undefined;
      this.checkInput("reservationOther_ContactFirstName", undefined);
      this.checkInput("reservationOther_ContactLastName", undefined);
      this.checkInput("reservationOther_ContactMobile", undefined);
      this.checkInput("reservationOther_ContactMobileCountryCode", undefined);
      this.checkInput("reservationOther_ContactEmail", undefined);
    },
    handleAddress(address) {
      this.$store.dispatch("reservationContactAddress1", address.streetAddress);
      this.$store.dispatch("reservationContactAddress2", address.apt);
      this.$store.dispatch("reservationContactCity", address.city);
      this.$store.dispatch("reservationContactState", address.region);
      this.$store.dispatch("reservationContactZip", address.postal);
    },
    pickUp() {
      this.receiverIsSet = !this.receiverIsSet;
      this.$store.dispatch("reservationSameAddress", this.sameAddress);
      if (this.sameAddress) {
        this.$store.dispatch("reservationContactFirstName", this.firstName);
        this.$store.dispatch("reservationContactLastName", this.lastName);
        this.$store.dispatch("reservationContactEmail", this.email);
        this.$store.dispatch("reservationContactMobile", this.mobile);
      }
      if (this.sameAddress && this.$store.getters.userAddress) {
        this.$store.dispatch(
          "reservationContactAddress1",
          this.$store.getters.userAddress1
        );
        this.$store.dispatch(
          "reservationContactAddress2",
          this.$store.getters.userAddress2
        );
        this.$store.dispatch(
          "reservationContactCity",
          this.$store.getters.userCity
        );
        this.$store.dispatch(
          "reservationContactState",
          this.$store.getters.userProvince
        );
        this.$store.dispatch(
          "reservationContactZip",
          this.$store.getters.userPostalCode
        );
      }
    },
    checkInput(store, model) {
      this.$store.dispatch(store, model);
    },
    async mobileValidation() {
      if (this.mobileCountryCode && this.mobile) {
        try {
          const response = await axios.post("user/IsMobileExist", {
            Mobile: this.mobile,
          });
          this.$store.dispatch("reservationPhoneVerification", !response.data);
        } catch (error) {
          console.log(error.response.data);
        }
      }
    },
    activeNextButton() {
      if (this.contactInfo) {
        this.nextButton("page1", false);
        document.body.getElementsByClassName('wizard__body__actions')[0].classList.add('d-flex')
      } else {
        this.nextButton("page1", true);
      }
    },
    handleMobile(mobile, isValid, c, countryCode) {
      if (isValid == true) {
        this.$store.dispatch("reservationPhoneVerification", true);

        this.$store.dispatch("reservationContactMobile", mobile);
        this.$store.dispatch(
          "reservationContactMobileCountryCode",
          countryCode
        );
        this.mobileCountryCode = countryCode;
        this.mobileIsValid = isValid;
        this.mobile = mobile;
      }
    },
    async getSalesAgent() {
      try {
        const response = await axios.post("user/SalesRepList", {
          CountryCode: this.$store.getters.destinationCountryCode,
        });
        this.theAgents = response.data;
      } catch (error) {
        console.log(error.response);
        this.errorMessage = error.response;
      }
    },
  },
  computed: {
    CountryCodes() {
      return countryCodes;
    },
    IsMobileVerified() {
      return this.user && this.user.IsMobileVerified;
    },
    IsEmailVerified() {
      return this.user && this.user.IsEmailVerified;
    },
    logState() {
      return Boolean(this.user && localStorage.getItem("token"));
    },
    searchCountry() {
      if (this.$store.getters.destinationCountry) {
        return this.$store.getters.destinationCountry;
      } else return null;
    },
    allowToProceed() {
      if (this.firstName && this.lastName && this.email && this.mobile)
        return true;
      else return false;
    },
    ...mapGetters([
      "reservationContactFirstName",
      "reservationContactLastName",
      "reservationContactEmail",
      "reservationContactMobile",
      "reservationContactState",
      "reservationContactCity",
      "reservationContactAddress1",
      "reservationContactAddress2",
      "reservationContactZip",
      "reservationPhoneVerification",
      "reservationAgent",
      "countries",
      "user",
    ]),
    contactInfo() {
      if (
        this.reservationAgent &&
        this.logState &&
        this.$store.getters.reservationAgent &&
        this.IsEmailVerified &&
        this.IsMobileVerified &&
        this.receiverIsSet
      ) {
        if (!this.sameAddress && this.$v.$invalid) return false;
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    try {
      if (this.$store.getters.destinationCountryCode) {
        this.getSalesAgent();
      }
    } catch {
      this.$router.replace("/");
    }

    if (this.user) {
      this.firstName = this.$store.getters.userFirstName;
      this.lastName = this.$store.getters.userLastName;
      this.email = this.$store.getters.userEmail;
      this.mobile = this.$store.getters.userMobile;
      this.country = this.searchCountry.Name;
      this.$store.dispatch(
        "reservationPhoneVerification",
        this.user.IsMobileVerified
      );
    }
    if (!this.$store.state.reservation?.reservationSameAddress) {
      this.other_firstName = this.$store.state.reservation?.reservationOther_ContactFirstName;
      this.other_lastName = this.$store.state.reservation?.reservationOther_ContactLastName;
      const mobileNumber = parsePhoneNumber(this.$store.state.reservation?.reservationOther_ContactMobile.toString())
      this.other_mobile = mobileNumber ? mobileNumber.number : this.$store.state.reservation?.reservationOther_ContactMobile;
      this.other_mobilePayload = mobileNumber
      this.other_mobile_countryCode = this.$store.state.reservation?.reservationOther_ContactMobileCountryCode;
      this.other_email = this.$store.state.reservation?.reservationOther_ContactEmail;
      this.sameAddress = false;
    } else if (this.$store.state.reservation?.reservationSameAddress === null) {
      this.checkInput("reservationSameAddress", true);
      this.sameAddress = true;
    }

    this.deliveryCountry = {
      Name: this.searchCountry.Name,
      Code: this.searchCountry.Code,
    };
  },
};
</script>

<style lang="scss">
.country-selector__input {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}

.select-country-container {
  // pointer-events: none;
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;
}

/*
.country-selector__toggle {
  display: none !important;
}
*/
.card-text {
  font-size: 1rem;
}
.card-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  padding: 1rem 0;
  margin-bottom: 0 !important;
  @media (max-width: 767px) {
    font-size: 20px;
  }
}
.card-minTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 0 !important;
  margin-bottom: 16px;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}
.card-label {
  color: #777;
}
.middleBar {
  width: 60%;
}
.card-subtitle {
  line-height: 20px;
  padding: 0;
  margin-top: 16px;
  color: #363636;
}
.choose-btn {
  min-width: 192px !important;
  height: 48px;
  font-weight: 600;
  font-size: 16px;
  margin-top: 16px;
  font-size: 20px;
  padding: 0 10px !important;
  border-radius: 40px !important;
  @media (max-width: 767px) {
    width: 100%;
  }
  &:before {
    display: none !important;
  }
}
.agent-wrapper {
  padding: 24px;
  column-gap: 24px;
  row-gap: 24px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 54.5vh;
  overflow-y: auto;
  @media (max-width: 1200px) {
    flex-direction: column;
    flex-wrap: initial;
  }
  @media (max-width: 767px) {
    padding: 16px;
    row-gap: 16px;
  }
  .agent-box {
    width: calc((100% / 2) - 12px);
    @media (max-width: 1200px) {
      width: 100%;
    }
  }
}
.modal-title {
  position: sticky;
  top: 0;
  z-index: 2;
  @media (max-width: 767px) {
    padding: 16px;
  }
  .font-600 {
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
.change-rep-btn {
  background: none !important;
  font-size: 16px !important;
  font-weight: bold;
  text-transform: capitalize;
  margin-top: 0;
  letter-spacing: 0;
  border-radius: 40px !important;
  padding: 0 10px !important;
  &:before {
    display: none !important;
  }
}
.mycontact {
  &-text {
    padding: 0;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  &-buttons {
    padding-bottom: 24px;
    justify-content: end;
    @media (max-width: 767px) {
      flex-direction: column;
      padding: 16px;
      padding-right: 0;
      padding-left: 0;
    }
    .v-btn {
      min-width: 190px !important;
      height: 56px !important;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      text-transform: capitalize;
      @media (max-width: 767px) {
        min-width: 100% !important;
        height: 48px !important;
      }
      &.primary {
        @media (max-width: 767px) {
          margin-bottom: 16px;
        }
      }
    }
  }
}
.provide-mobile {
  padding: 0;
  margin-bottom: 24px;
  &-label {
    margin-bottom: 16px;
  }
}
.radio-card-holder {
  padding: 24px;
  @media (max-width: 767px) {
    padding: 16px;
  }
  .v-messages {
    display: none;
  }
}

.reservation-userinfo-wrapper {
  padding: 0 24px;
  @media (max-width: 767px) {
    padding: 0 16px;
  }
}
.reservation-userinfo {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  @media (max-width: 575px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .item {
    display: flex;
    &:not(:last-of-type) {
      margin-bottom: 24px;
      @media (max-width: 575px) {
        margin-bottom: 16px;
      }
    }
  }
  .card-label {
    min-width: 130px;
    margin-right: 20px;
    @media (max-width: 575px) {
      display: none;
    }
  }
}
.contact-form {
  width: 450px;
  margin-top: 8px;
  padding: 24px 48px;
  @media (max-width: 575px) {
    width: 100%;
    padding: 16px 16px;
  }
  input {
    height: 48px;
    max-height: 48px;
    border-radius: 35px;
    border: 1px solid #c6c6c6;
    padding: 0 16px !important;
    outline: none !important;
    &.input-tel__input {
      border-right: 1px solid #ccc !important;
      border-radius: 0 38px 38px 0 !important;
      padding-top: 14px !important;
    }
  }
  .v-text-field > .v-input__control > .v-input__slot::before,
  .v-text-field > .v-input__control > .v-input__slot::after {
    display: none !important;
  }
  .v-messages__message {
    margin: 8px;
  }
  .country-selector__input {
    border: 1px solid #ccc !important;
    border-right: 0 !important;
    border-radius: 38px 0 0 38px;
  }
  .input-tel.is-valid .input-tel__input,
  .country-selector.is-valid .country-selector__input {
    border-color: #ccc !important;
  }
  .country-selector {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 2px;
      height: 33px;
      background-color: #c6c6c6;
      right: 0;
      top: 50%;
      z-index: 4;
      transform: translateY(-50%);
    }
  }
}
.submit-btn {
  margin-top: 24px;
  button {
    font-size: 16px !important;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 0;
  }
}
.ReservationWizard .country-selector__country-flag {
  top: 19px !important;
}
/*
.input-tel__label {
  display: none !important;
}
*/
</style>

<style lang="scss">
.modal-title {
  .v-btn {
    width: 30px;
    margin-right: -5px;
  }
}
.wizard__body__actions {
  display: none!important;
  a {
    min-width: 192px;
    height: 56px !important;
    display:flex;
    justify-content: center;
    align-items: center;
    i {
      font-family: "FontAwesome";
      &.vgw-next {
        margin-left: 8px;
        &:before {
          content: "\f061";
        }
      }
      &.vgw-prev {
        margin-right: 8px;
        &:before {
          content: "\f060";
        }
      }
      &:after {
        display: none !important;
      }
    }
  }
}
.margin-bottom-24 {
  margin-bottom: 24px;
  @media (max-width: 991px) {
    margin-bottom: 16px;
  }
}
.padding-24 {
  padding: 0 24px;
  @media (max-width: 991px) {
    padding: 0 16px;
  }
}
</style>
