<template>
  <div class="margin-bottom-24">
    <v-card outlined class="margin-bottom-24 question">
      <div
        class="d-flex justify-space-between align-center question-headline"
        @click="showSalesRep = !showSalesRep"
      >
        <div class="d-flex align-center">
          <v-card-title class="card-title p-0">
            {{ $t("Reservation.Question") }}
          </v-card-title>
          <a href="#" class="align-end">
            {{ $t("Reservation.TalkToYourRepresentative") }}
          </a>
        </div>
        <v-icon v-if="!showSalesRep">mdi-chevron-down</v-icon>
        <v-icon v-else>mdi-chevron-up</v-icon>
      </div>
      <TheSalesAgents
        v-if="$store.getters.reservationAgent && showSalesRep"
        :agents="$store.getters.reservationAgent"
        :selected="true"
        class="mt-4"
      />
    </v-card>
    <v-card outlined class="margin-bottom-24">
      <div class="padding-24">
        <v-card-title class="card-title mr-4 pb-0"
          >{{ $t("Reservation.PriceSummary") }} -
          <span class="unit">{{ currentRate }}</span></v-card-title>
        <v-divider />
        <v-card-text class="px-0 pt-0">
          <div class="item">
            <div class="name">{{ $t("Reservation.VehiclePrice") }}</div>
            <div class="text-right total-price">
              {{ 
                    vehiclePrice ? currencyFormatter(vehiclePrice) : '-'
                }}
            </div>
          </div>
          <div class="item">
            <div class="name">{{ $t("Reservation.InspectionFees") }}</div>
            <div class="text-right">{{ $t("Reservation.Included") }}</div>
          </div>
          <div class="item">
            <div class="name">{{ $t("Reservation.ShippingDeliveryFees") }}</div>
            <div class="text-right">{{ $t("Reservation.Included") }}</div>
          </div>
          <div class="item">
            <div class="name">{{ $t("Overview.30DaysLimitedWarranty") }}</div>
            <div class="text-right">{{ $t("Reservation.Included") }}</div>
          </div>
          <div class="item">
            <div class="name">
              {{ $t("Overview.CustomDutiesAndTaxes") }} 
                <b-link v-if="includeFees" @click="$emit('apply', false)" class="mini-font text-primary"
                      >({{ $t("Overview.remove") }})</b-link>
                    <b-link v-else @click="$emit('apply', true)" class="mini-font text-primary"
                      >({{ $t("Overview.add") }})</b-link>
            </div>
            <div class="text-right">
              <span v-if="includeFees">
                        <!-- {{ $t("Overview.Included") }} -->
                        {{ totalPrice && vehiclePrice ? currencyFormatter(totalPrice - vehiclePrice) : '-' }}
                      </span>
                      <span v-else>
                        {{ $t("Overview.NotIncluded") }}
                      </span>
            </div>
          </div>
          <v-divider />
          <v-row class="total-price-row">
            <v-col class="text-h5 font-weight-bold">{{
              $t("Reservation.TotalPrice")
            }}</v-col>
            <v-col class="text-right text-h5 font-weight-bold">{{
              totalPrice ? currencyFormatter(fees) : '-'
            }}</v-col>
          </v-row>
          <v-row>
            <v-col class="text-primary pt-1">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <span color="primary" v-bind="attrs" v-on="on">
                    {{ $t("Reservation.ViewInOtherCurrency") }}
                  </span>
                </template>
                <v-list>
                  <v-list-item
                    v-for="currency in currenciesForBuyer"
                    :key="currency.Code"
                    @click="setCurrency(currency.Code)"
                  >
                    <v-list-item-title>{{ currency.CurrencyName }} - {{ currency.Sign }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-card>
    <v-alert color="#D3E4FC" dismissible>
      {{ $t("Reservation.DepositDesc") }}
      <span class="text-primary">{{ $t("Reservation.LearnMore") }}</span>
    </v-alert>
    <v-card outlined class="margin-bottom-24 deposit-card">
      <div class="padding-24">
        <v-card-title class="card-title mr-4 pb-0"
          >{{ $t("Reservation.Deposit") }} -
          <span class="unit">{{ currentRate }}</span></v-card-title
        >
        <v-divider />
        <v-card-text class="px-0 pt-0">
          <v-row>
            <v-col class="pb-0"
              ><span class="font-weight-bold"
                >{{ $t("Reservation.DepositAmount") }} -
              </span>
              <span class="text-success">{{ $t("Reservation.Refundable") }}</span>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_399_3030)">
                  <path
                    d="M7 0.875C7.82852 0.875 8.62969 1.03633 9.38438 1.35625C10.1145 1.66523 10.768 2.10547 11.3313 2.66875C11.8945 3.23203 12.3348 3.88555 12.6438 4.61562C12.9637 5.37031 13.125 6.17148 13.125 7C13.125 7.82852 12.9637 8.62969 12.6438 9.38438C12.3348 10.1145 11.8945 10.768 11.3313 11.3313C10.768 11.8945 10.1145 12.3348 9.38438 12.6438C8.62969 12.9637 7.82852 13.125 7 13.125C6.17148 13.125 5.37031 12.9637 4.61562 12.6438C3.88555 12.3348 3.23203 11.8945 2.66875 11.3313C2.10547 10.768 1.66523 10.1145 1.35625 9.38438C1.03633 8.62969 0.875 7.82852 0.875 7C0.875 6.17148 1.03633 5.37031 1.35625 4.61562C1.66523 3.88555 2.10547 3.23203 2.66875 2.66875C3.23203 2.10547 3.88555 1.66523 4.61562 1.35625C5.37031 1.03633 6.17148 0.875 7 0.875ZM7 0C3.13359 0 0 3.13359 0 7C0 10.8664 3.13359 14 7 14C10.8664 14 14 10.8664 14 7C14 3.13359 10.8664 0 7 0Z"
                    fill="#777777"
                  />
                  <path
                    d="M8.31797 9.625H8.09375C7.97344 9.625 7.875 9.52656 7.875 9.40625V6.81406C7.875 6.3957 7.56055 6.0457 7.14492 5.99922C7.07656 5.99102 7.01367 5.98555 6.96172 5.98555C6.31367 5.98555 5.74219 6.31094 5.40313 6.80859L5.40039 6.81133V6.80859C5.37305 6.86328 5.35391 6.95352 5.41953 6.98633C5.43594 6.99453 5.45234 7 5.46875 7H5.90625C6.02656 7 6.125 7.09844 6.125 7.21875V9.40625C6.125 9.52656 6.02656 9.625 5.90625 9.625H5.68203C5.44414 9.625 5.25 9.81914 5.25 10.057V10.0652C5.25 10.3031 5.44414 10.4973 5.68203 10.4973H8.31797C8.55586 10.4973 8.75 10.3031 8.75 10.0652V10.057C8.75 9.81914 8.55586 9.625 8.31797 9.625ZM7 3.5C6.51602 3.5 6.125 3.89102 6.125 4.375C6.125 4.85898 6.51602 5.25 7 5.25C7.48398 5.25 7.875 4.85898 7.875 4.375C7.875 3.89102 7.48398 3.5 7 3.5Z"
                    fill="#777777"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_399_3030">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </v-col>
            <v-col class="text-right pb-0"
              ><span class="total-price">{{ deposit ? currencyFormatter(deposit) : '-' }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" style="white-space: nowrap"
              >{{ $t("Reservation.RemainingBalance") }}
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_399_3030)">
                  <path
                    d="M7 0.875C7.82852 0.875 8.62969 1.03633 9.38438 1.35625C10.1145 1.66523 10.768 2.10547 11.3313 2.66875C11.8945 3.23203 12.3348 3.88555 12.6438 4.61562C12.9637 5.37031 13.125 6.17148 13.125 7C13.125 7.82852 12.9637 8.62969 12.6438 9.38438C12.3348 10.1145 11.8945 10.768 11.3313 11.3313C10.768 11.8945 10.1145 12.3348 9.38438 12.6438C8.62969 12.9637 7.82852 13.125 7 13.125C6.17148 13.125 5.37031 12.9637 4.61562 12.6438C3.88555 12.3348 3.23203 11.8945 2.66875 11.3313C2.10547 10.768 1.66523 10.1145 1.35625 9.38438C1.03633 8.62969 0.875 7.82852 0.875 7C0.875 6.17148 1.03633 5.37031 1.35625 4.61562C1.66523 3.88555 2.10547 3.23203 2.66875 2.66875C3.23203 2.10547 3.88555 1.66523 4.61562 1.35625C5.37031 1.03633 6.17148 0.875 7 0.875ZM7 0C3.13359 0 0 3.13359 0 7C0 10.8664 3.13359 14 7 14C10.8664 14 14 10.8664 14 7C14 3.13359 10.8664 0 7 0Z"
                    fill="#777777"
                  />
                  <path
                    d="M8.31797 9.625H8.09375C7.97344 9.625 7.875 9.52656 7.875 9.40625V6.81406C7.875 6.3957 7.56055 6.0457 7.14492 5.99922C7.07656 5.99102 7.01367 5.98555 6.96172 5.98555C6.31367 5.98555 5.74219 6.31094 5.40313 6.80859L5.40039 6.81133V6.80859C5.37305 6.86328 5.35391 6.95352 5.41953 6.98633C5.43594 6.99453 5.45234 7 5.46875 7H5.90625C6.02656 7 6.125 7.09844 6.125 7.21875V9.40625C6.125 9.52656 6.02656 9.625 5.90625 9.625H5.68203C5.44414 9.625 5.25 9.81914 5.25 10.057V10.0652C5.25 10.3031 5.44414 10.4973 5.68203 10.4973H8.31797C8.55586 10.4973 8.75 10.3031 8.75 10.0652V10.057C8.75 9.81914 8.55586 9.625 8.31797 9.625ZM7 3.5C6.51602 3.5 6.125 3.89102 6.125 4.375C6.125 4.85898 6.51602 5.25 7 5.25C7.48398 5.25 7.875 4.85898 7.875 4.375C7.875 3.89102 7.48398 3.5 7 3.5Z"
                    fill="#777777"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_399_3030">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs></svg>
              </v-col>
            <v-col class="text-right"
              ><span class="total-price text-primary">{{
                totalPrice && deposit ? currencyFormatter(fees - deposit) : '-'
              }}</span></v-col
            >
          </v-row>
        </v-card-text>
      </div>
    </v-card>
    <v-card outlined class="margin-bottom-24">
      <div class="pay-wrapper">
        <v-card-title class="card-title mr-md-4">{{
          $t("Reservation.HowDeposit")
        }}</v-card-title>
        <v-radio-group v-model="paymentType">
          <v-expansion-panels
            class="payment-collapse"
            v-model="checkSelectedPaymentCollapse"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-radio @click.stop :label="$t('Reservation.cash')" value="cashMenu" />
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-alert prominent color="#2196f3" dark text dense>
                  <div class="row-col" align="center">
                    <v-col class="grow">
                      <div>
                        {{ $t("Reservation.ContactPashAgent") }}
                      </div>
                      <v-btn
                          @click="cashPayment({ type: 'cash' })"
                          rounded
                          x-large
                          color="#2196f3"
                          dark
                          class="mx-auto mt-5"
                          elevation="0"
                      >
                        {{ $t("Reservation.ConfirmNow") }}
                      </v-btn>

                    </v-col>
                  </div>
                </v-alert>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-radio
                  @click.stop
                  :label="$t('Reservation.MobilePayment')"
                  value="mobileMenu"
                />
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-alert prominent color="#2196f3" dark text dense>
                  <div class="row-col" align="center">
                    <v-col class="grow">
                      <div>
                        {{ $t("Reservation.ContactPashAgent") }}
                      </div>
                      <v-btn
                          @click="cashPayment({ type: 'mobile' })"
                          rounded
                          x-large
                          color="#2196f3"
                          dark
                          class="mx-auto mt-5"
                          elevation="0"
                      >
                        {{ $t("Reservation.ConfirmNow") }}
                      </v-btn>

                    </v-col>
                  </div>
                </v-alert>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- <v-expansion-panel>
              <v-expansion-panel-header>
                <v-radio
                  @click.stop
                  :label="$t('Reservation.CreditCard')"
                  value="creditMenu"
                />
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <CreditCard
                  v-if="checkSelectedPaymentCollapse === 2"
                  @change="creditInfoChanged"
                  v-model="cardDetail"
                  :trans="creditTranslations"
                  ref="CreditCardHolder"
                />
                <div class="text-right">
                  <v-btn
                    color="primary"
                    x-large
                    className="mx-auto"
                    :disabled="!checkPayButton"
                    @click="cashPayment({ type: 'credit', cardDetail })"
                  >
                    {{ $t("Reservation.ReservePayBtn") }}
                  </v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel> -->
          </v-expansion-panels>
        </v-radio-group>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import CreditCard from "v-credit-card";
import "v-credit-card/dist/VCreditCard.css";
import TheSalesAgents from "@/components/reservation/TheSalesAgentCard";
import listingTotals from '@/components/mixins/listingTotals.js'

export default {
  props: {
    nextButton: { type: Function },
    includeFees: {
      type: Boolean,
      required:true
    }
  },
  components: {
    CreditCard,
    TheSalesAgents,
  },
  mixins: [listingTotals],
  updated() {
    this.activeNextButton();
  },
  watch: {
    paymentType() {
      this.cardDetail = {
        name: "",
        cardNumber: "",
        expiration: "",
        security: "",
      };
    },
  },
  data() {
    return {
      originalCurrency: null,
      paymentType: null,
      portName: null,
      exportPort: null,
      entryPort: null,
      arriveTime: null,
      agentPop: false,
      theAgents: null,
      deposit: null,
      showCard: true,
      cashMenu: false,
      creditMenu: false,
      mobileMenu: false,
      showSalesRep: false,
      errorMessage: null,
      page: "https://stripe.com/" + this.$store.getters.lang + "-ca/about",
      cardDetail: {
        name: "",
        cardNumber: "",
        expiration: "",
        security: "",
      },
      creditTranslations: {
        name: {
          label: this.$t("Reservation.CreditCardName"),
          placeholder: this.$t("Reservation.CreditCardNamePlaceHolder"),
        },
        card: {
          label: this.$t("Reservation.CreditCard"),
          placeholder: this.$t("Reservation.CreditCardPlaceHolder"),
        },
        expiration: {
          label: this.$t("Reservation.CreditCardExpiration"),
        },
        security: {
          label: this.$t("Reservation.CreditCardSecurity"),
          placeholder: this.$t("Reservation.CreditCardSecurityPlaceHolder"),
        },
      },
    };
  },
  methods: {
    creditInfoChanged(values) {
      for (const key in values) {
        this.cardDetail[key] = values[key];
      }
    },
    currencyFormatter(value) {
      return new Intl.NumberFormat(this.$store.getters.currencyCulture, {
        style: "currency",
        currency: this.$store.getters.currency,
        minimumFractionDigits: value % 1 != 0 ? 2 : 0,
      }).format(value);
    },
    removeAgent() {
      this.$store.dispatch("reservationAgent", null);
      this.nextButton("page1", true);
    },
    async getSummaryFees() {
      try {
        const response = await axios.post("Listing/DepositeAmount", {
          DeliveryCountryCode: this.$store.getters.destinationCountryCode,
        });
        this.deposit = response.data.Amount;
      } catch (error) {
        console.log(error.response.data);
        this.errorMessage = error.response.data;
      }
    },
/*
    async getSummaryFees() {
      const defaultCurrencyCode = axios.defaults.headers.common["currencyCode"]
      try {
        const getDeliveryCountry = this.$store.getters['currencies'].find(currency => currency.CountryCode === this.$store.getters.destinationCountryCode)
        axios.defaults.headers.common["currencyCode"] = getDeliveryCountry.Code;
        const response = await axios.post("Listing/DepositeAmount", {
          DeliveryCountryCode: this.$store.getters.destinationCountryCode,
        });
        this.deposit = response.data.Amount;
      } catch (error) {
        console.log(error.response.data);
        this.errorMessage = error.response.data;
      }finally {
        axios.defaults.headers.common["currencyCode"] = defaultCurrencyCode;
      }
    },
*/
    setCurrency(e) {
      if (this.originalCurrency === null) this.originalCurrency = axios.defaults.headers.common["currencyCode"];
      console.log(e)
      this.displayCurrency = false;
      this.$store.dispatch("setCurrency", e);
      this.setTotals();
      this.$store.dispatch("getListing", {
        id: this.$route.params.id,
      });
      
      this.getSummaryFees();
    },
    activeNextButton() {
      if (!this.$store.getters.reservationAgent) {
        this.nextButton("page1", true);
      } else {
        this.nextButton("page1", false);
      }
    },
    gotoStripe() {
      this.$store.dispatch("reservationKeepData", true);
      window.location.href = this.link;
    },
    async cashPayment(paymentType) {
      try {
        await this.$emit("submitReservation", {...paymentType});
        this.cardDetail = {
          name: "",
          cardNumber: "",
          expiration: "",
          security: ""
        }
        this.$refs.CreditCardHolder.data({
          name: "",
          cardNumber: "",
          expiration: "",
          security: ""
        })
        //this.$refs.CreditCardHolder.$destroy()
      }catch (error) {
        if (process.env.NODE_ENV === "development") console.log(error)
        this.$refs.CreditCardHolder.$destroy();
      }
    }
  },
  computed: {
    ...mapGetters([
      "currency",
      "currencies",
      "activeBuyerCountries",
      "currenciesForBuyer"
    ]),
    checkPayButton() {
      if (
        this.cardDetail.name?.length > 0 &&
        this.cardDetail.cardNumber?.length === 19 &&
        this.cardDetail.expiration.length === 5 &&
        (this.cardDetail.security.length >= 3 && this.cardDetail.security.length <= 4)
      ) {
        return true;
      }
      return false;
    },
    checkSelectedPaymentCollapse: {
      get() {
        let selected = null;
        switch (this.paymentType) {
          case "cashMenu":
            return 0;
          case "mobileMenu":
            return 1;
          case "creditMenu":
            return 2;
        }
        return selected;
      },
      set(paymentType) {
        switch (paymentType) {
          case 0:
            this.paymentType = "cashMenu";
            break;
          case 1:
            this.paymentType = "mobileMenu";
            break;
          case 2:
            this.paymentType = "creditMenu";
            break;
        }
      },
    },
    fees() {
      return this.listing.Cardsdata.Price.Amount;
    },
    currentRate() {
      // const currencies = this.currencies;
      const currencies = this.currenciesForBuyer;
      const currentRateName = currencies.find((item) => item.Code === this.currency);
      if (currentRateName) return currentRateName.CurrencyName;
      return null;
    },
    searchCountry() {
      let dest = null;
      if (this.$store.getters.destinationCountry) {
        return this.$store.getters.destinationCountry;
      } else if (dest) {
        return JSON.parse(dest);
      } else return null;
    },
    listing() {
      if (this.$store.getters.listing) {
        return this.$store.getters.listing;
      } else {
        return null;
      }
    },
    priceData() {
      if (this.listing) {
        return JSON.parse(this.listing.Prices)[0];
      } else return "";
    },
  },
  beforeMount() {
    this.getSummaryFees();
  },
  beforeDestroy() {
    //we want the user to keep their selected currency when they leave the page
    if (this.originalCurrency != null) this.$store.dispatch("setCurrency", this.originalCurrency);
  },
  mounted() {
    this.setTotals()
  }
};
</script>

<style lang="scss">

.popover {
  max-width: 1000px !important;
}

.cancelButtonR {
  width: 160px;
  position: relative;
  bottom: -54px;
  z-index: 1;
}

.middleBar {
  position: relative;
  bottom: -33px;
  float: right;
  margin-right: 194px;
  border-style: solid;
  border-width: 2px;
  border-color: $half-dark;
  background: $half-dark;
}

.custom-select {
  background: none !important;
  background-color: white !important;
}

@media (max-width: 999px) {
  .popover {
    max-width: 500px !important;
  }
}
.payment-collapse .v-label {
  font-weight: 600;
}
.payment-collapse .v-label {
  font-size: 16px !important;
  font-weight: 400 !important;
}
.pay-wrapper {
  .v-messages {
    display: none !important;
  }
}
</style>

<style lang="scss" scoped>
.question-headline {
  .card-title {
    white-space: nowrap;
  }
}
.row-col {
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .v-btn {
    letter-spacing: 0;
    white-space: wrap;
    @media (max-width: 767px) {
      padding: 0 16px;
    }
  }
  .col {
    @media (max-width: 767px) {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
.question {
  padding: 24px;
  user-select: none;

  &-headline {
    cursor: pointer;
  }
  @media (max-width: 991px) {
    padding: 16px;
  }
  .card-title {
    margin-right: 8px;
  }
}
.unit {
  font-size: 14px;
  margin-left: 5px;
  margin-top: 3px;
  color: #444;
}

.total-price {
  font-size: 20px;
  color: #1c1c1c;
  font-weight: 600;
}
.item {
  display: flex;
  justify-content: space-between;
  color: #777777;
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
  .name {
    color: #1c1c1c;
  }
}
.total-price-row {
  .text-h5 {
    font-size: 20px !important;
  }
  .col {
    padding-bottom: 0;
  }
}
.v-alert {
  margin-bottom: 24px;
  @media (max-width: 991px) {
    mix-blend-mode: 16px;
  }
}
.pay-wrapper {
  padding: 24px;
  @media (max-width: 991px) {
    padding: 16px;
  }

  .card-title {
    padding: 0;
    margin-bottom: 8px;
  }
}
.v-expansion-panels {
  padding-left: 24px;
  @media (max-width: 767px) {
    padding-left: 0;
  }
}
.v-expansion-panel {
  min-height: 40px;
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
  button {
    padding: 0 16px;
  }
  &:before {
    display: none;
  }
  &:not(:first-child)::after {
    display: none;
  }
}

.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0;
}
.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0;
}
.deposit-card {
  svg {
    width: 17px;
    height: 17px;
    margin-left: 8px;
  }
}
</style>
